import {GetStaticPaths, GetStaticProps} from 'next';

import {Layout} from 'src/components';
import {NoSSR} from 'src/components/NoSSR';
import {CheckoutAuthPageTemplate} from 'src/components/checkout-auth-page/CheckoutAuthPageTemplate';
import {SEOProps} from 'src/components/layout/SEO';
import {regionalConfigsArr} from 'src/config/regions';
import {PageParams} from 'src/types';

export const getStaticPaths: GetStaticPaths = async () => {
  const paths: {params: PageParams}[] = [];

  for (const config of regionalConfigsArr) {
    const {regionCode, locales} = config;

    for (const locale of locales) {
      paths.push({params: {region: regionCode, locale}});
    }
  }

  return {paths, fallback: false};
};

export const getStaticProps: GetStaticProps<PageParams, PageParams> = async (
  ctx
) => {
  const {params} = ctx;

  if (!params) {
    throw new Error('Could not find params for sign in page');
  }

  const {region, locale} = params;

  return {
    props: {
      region,
      locale,
    },
  };
};

const SignInPage: React.FC<PageParams> = (props) => {
  const {region, locale} = props;

  const seo: SEOProps = {
    title: 'Sign In', // TODO localize this string
    description: '',
    noIndex: true,
    includeInRegions: ['all'],
    slug: 'signin',
    slugPrefix: '',
  };

  return (
    <Layout seo={seo} pageContext={{region, locale}} pure>
      <NoSSR>
        <CheckoutAuthPageTemplate authPageType={'signIn'} />
      </NoSSR>
    </Layout>
  );
};

export default SignInPage;
